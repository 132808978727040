<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div v-else class="feedback-survey">
    <h5 class="text-center pb-3" style="opacity: 0.85;" :class="Object.keys(surveyQuesData).length <= 2 ? 'pt-5' : ''"><b> Rate Your Experience </b></h5>
    <div class="row justify-content-md-center pb-3" v-for="(ques, index) in surveyQuesData" :key="index">
      <survey-block 
        :question="ques"
        :questionId="Object.keys(surveyQuesData)[index - 1]"
      />
    </div>
    <div class="row justify-content-md-center pb-3">
      <div class="col-3">
        <button class="btn btn-main btn-block text-uppercase add-opacity pointer"
          @click="saveAndProceed"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>
<script>
var $ = require('jquery')
window.jQuery = $
import surveyBlock from './common/surveyBlock.vue'
import {mapGetters} from 'vuex'
import Loader from './common/loader.vue'
import Vue from 'vue'
export default {
  data () {
    return {
      answer: {}
    }
  },
  components: {
    surveyBlock,
    Loader
  },
  computed: {
    ...mapGetters({
      surveyQuesData: 'surveyQuesData',
      loading: 'loading'
    })
  },
  created () {
    this.$store.dispatch('GET_SURVEY_QUESTIONS')
  },
  methods: {
    saveAndProceed () {
      Object.keys(this.surveyQuesData) && Object.keys(this.surveyQuesData).length && Object.keys(this.surveyQuesData).forEach((quesKey) => {
        let elem = document.getElementsByName('survey_' + quesKey)
        if (elem && elem.length) {
          Array.from(elem).forEach((itm) => {
            if (itm.checked === true) {
              this.answer[quesKey] = itm.value
              return
            }
          })
        }
      })
      if (Object.keys(this.surveyQuesData).length !== Object.keys(this.answer).length) {
        Vue.toasted.show('Please fill all Feedback Questions', {
          theme: 'primary',
          className: 'toasted-customred',
          position: 'top-right',
          duration: 5000
        })
      } else{
        Vue.ls.set('tss-answers', this.answer)
        this.$router.replace('/order-details')
      }
    }
  }
}
</script>

<style scoped>
.feedback-survey{ 
  padding: 20px;
}
</style>